import type { ProductConfig } from 'src/config/product';

import { CATEGORIES } from 'src/constants/typologies';
import { countryCode, domainName, language } from './constants';

import { isChristmasTime } from 'src/utils/client/christmas';
import { colors } from 'src/utils/colors';
import { isProd } from 'src/utils/environment';
import { getOrigin } from '../utils';

import { agenciesWithBaseHeaderColor } from './agenciesWithBaseHeaderColor';
import appIcon from './assets/app_icon.svg';
import appleTouchIcon from './assets/apple-touch-icon.png';
import favicon from './assets/favicon.ico';
import favicon16 from './assets/favicon-16x16.png';
import favicon32 from './assets/favicon-32x32.png';
import logoGoverno from './assets/logo_governo.png';
import logoGoverno2x from './assets/logo_governo@2x.png';
import logoChristmasAlt from './assets/logo-immotop-alt-natale.svg';
import logoNegative from './assets/logo-immotop-negative.svg';
import logoPositive from './assets/logo-immotop-positive.svg';
import markNegative from './assets/mark-immotop-negative.svg';
import markPositive from './assets/mark-immotop-positive.svg';
import safariPinnedTab from './assets/safari-pinned-tab.svg';
import { mapConfig } from './map';
import { alternateHeaderUrls, discoverUrls, headerUrls, urls } from './urls';

export const config: ProductConfig = {
  ...urls,
  defaultSearchPrefix: '/vente-maisons-appartements',
  name: 'immotop-lu',
  domainName,
  brandName: 'IMMOTOP.LU',
  metricsDomain: 'immotop_lu',
  origin: getOrigin(domainName),
  lead: {
    emailRequired: true,
    phoneRequired: false,
  },
  countryCode,
  country: 'luxembourg',
  defaultLocale: language,
  blog: 'news/',
  external_crm_host: 'https://pro.immotop.lu',
  agencySignUp: 'login.html',
  allowedLanguages: {
    pt: {
      code: 'pt',
      label: 'Português',
    },
    de: {
      code: 'de',
      label: 'Deutsch',
    },
    en: {
      code: 'en',
      label: 'English',
    },
    fr: {
      code: 'fr',
      label: 'Français',
    },
    it: {
      code: 'it_IT',
      label: 'Italiano',
    },
    es: {
      code: 'es_ES',
      label: 'Español',
    },
    ru: {
      code: 'ru',
      label: 'Русский',
    },
    el: {
      code: 'el',
      label: 'Ελληνικά',
    },
  },
  phoneCountryCode: '352',
  logoConfig: {
    url: isChristmasTime() ? logoChristmasAlt : logoNegative,
    positiveUrl: logoPositive,
    width: 238,
    height: 48,
    mobile: {
      altURL: markNegative,
      positiveURL: markPositive,
      width: 48,
      height: 48,
    },
  },
  logoConfigPrintPage: {
    url: logoPositive,
    width: 146,
    height: 29,
  },
  headlineSvg: false,
  assets: {
    appleTouchIcon: appleTouchIcon.src,
    maskIcon: safariPinnedTab,
    shortcutIcon: favicon.src,
    themecolor: colors.brand['01']['500'],
    favicons: [
      {
        url: favicon32.src,
        width: 32,
        height: 32,
        type: 'image/png',
        rel: 'icon',
      },
      {
        url: favicon16.src,
        width: 16,
        height: 16,
        type: 'image/png',
        rel: 'icon',
      },
    ],
  },
  mapConfig,
  cityGuide: {
    mapConfig: {
      ...mapConfig,
      minZoom: 6,
    },
    geographyInfoMapZoom: {
      country: 8,
      region: 9,
      province: 11,
      city: 12,
      cityZone: 12,
      road: 16,
    },
    apiMapZoom: {
      country: 8,
      region: 9,
      province: 10,
      city: 11,
    },
  },
  geographySearch: {
    drawEnabled: true,
  },
  geocodeIsolinesApiKey: 'immotop',
  geocodeReverseApiKey: 'immotop',
  geocodeAddressApiKey: 'immotop',
  geocodeAddressApiOrigin: 'https://www.immotop.lu',
  mapResultsLimit: 500000,
  mapResultMaxLabel: 2000,
  mapMaxResultsPerPage: 25,
  listResultsLimit: 500000,
  listResultMaxLabel: 2000,
  listMaxResultsPerPage: 25,
  energyRange: {
    high: ['A', 'B', 'C'],
    medium: ['D', 'E', 'F'],
    low: ['G', 'H', 'I'],
  },
  user: {
    publishedAdsUrl: '/utente/annunci/pubblicati/',
    savedAdsUrl: '/user/saved/',
    blacklistedAdsUrl: '/utente/annunci/nascosti/',
    lastAdsUrl: '/utente/annunci/recenti/',
    savedSearchesUrl: '/user/searches/',
    // messagesUrl: '/utente/messaggi/',
    logoutUrl: '/api-next/user/logout/',
    loginUrl: '/api-next/user/login/',
    registerUrl: '/api-next/user/register/',
    passwordRecoveryUrl: '/api-next/user/password-retrieve/',
    profileUrl: '/utente/profilo/',
    editUrl: (realEstateId) => `/utente/annuncio/${realEstateId}`,
    upgradeUrl: (realEstateId) =>
      `/utente/annuncio/${realEstateId}/visibilita/`,
  },
  socials: {
    instagramUrl: 'https://www.instagram.com/immotop.lu/',
    facebookUrl: 'https://www.facebook.com/ImmotopLuFanPage/',
    twitterUrl: 'https://twitter.com/immotoplu/',
    linkedinUrl: 'https://www.linkedin.com/company/immotoplu/',
  },
  terms: {
    conditionUrl: '/terms/',
    privacyUrl: '/terms/privacy/',
    cookiesUrl: '/terms/cookie/',
  },
  hasFooterLinks: true,
  realEstatePath: '/annonces/',
  facebookAppId: 2011599832522097,
  gtm: {
    id: 'GTM-MDPQDS',
  },
  mapchartImgServiceVersion: '2024071701', // remember to increase polygonsServiceVersion value
  vhostUrlCdnMaps: !isProd
    ? 'https://citymaps-staging.pepita.io/comuni/indomio-lu/'
    : 'https://s1.immotop.lu/c/',
  staticMapUrl: 'https://maps.im-cdn.it/static',
  appStores: {
    iOS: 'https://apps.apple.com/it/app/immotop-lu/id370441964',
    android: 'https://play.google.com/store/apps/details?id=lu.immotop.android',
    huawei: 'https://appgallery.huawei.com/#/app/C104860193',
  },
  appConfig: {
    img: appIcon,
    iOS: {
      app_name: 'immotop',
    },
    android: {
      app_host: 'www.immotop.lu',
      uri_scheme: 'https',
      app_package: 'lu.immotop.android',
    },
    huawei: {
      app_package: 'lu.immotop.android',
    },
  },
  preconnectUrls: ['https://pwm.im-cdn.it', 'https://www.googletagmanager.com'],
  brazeConfig: {
    apiKey: isProd
      ? '2b7be311-fbba-4591-8e7e-584ba3073c84'
      : '4f51e78a-67d5-4c38-91c5-61d9856e876c',
    baseUrl: 'sdk.fra-01.braze.eu',
  },
  headerUrls,
  alternateHeaderUrls,
  discoverUrls,
  alternateDiscoverUrls: discoverUrls,
  visibilities: {
    supervetrina: {
      label: 'lu_lbl_supervetrina', // i18n-extract-keys ["lu_lbl_supervetrina"]
      style: 'lu_supervetrina',
      isMosaic: true,
    },
    vetrina: {
      label: 'lu_lbl_vetrina', // i18n-extract-keys ["lu_lbl_vetrina"]
      style: 'lu_vetrina',
      isMosaic: true,
    },
    star: {
      label: 'lu_lbl_star', // i18n-extract-keys ["lu_lbl_star"]
      style: 'lu_star',
      isMosaic: true,
    },
    top: {
      label: 'lu_lbl_top', // i18n-extract-keys ["lu_lbl_top"]
      style: 'lu_top',
      isMosaic: true,
    },
    premium: {
      label: 'lu_lbl_premium', // i18n-extract-keys ["lu_lbl_premium"]
      style: 'lu_premium',
    },
  },
  mainPartnerHome: {
    img: {
      src: logoGoverno.src,
      src_2x: logoGoverno2x.src,
      width: 300,
      alt: 'Logement.lu',
    },
    url: 'https://logement.public.lu/fr.html',
    target: '_blank',
    rel: 'nofollow',
  },
  agenciesExpertResultsLimit: 25,
  spuerkeess: {
    sdkUrl: 'https://cdn.spuerkeess.lu/sdk/spuerkeess-sdk.js',
    fallbackLang: 'fr',
    sdkAvailableLang: ['fr', 'de', 'en'],
    apiUrl: 'https://www.spuerkeess.lu/api/housing',
  },
  laluxAssurance: {
    sdkUrl: 'https://www.lalux.lu/sdk/laluxsdk.js',
    fallbackLang: 'fr',
    sdkAvailableLang: ['fr', 'de', 'en', 'pt'],
    sdkHouseType: {
      appartement: 1,
      maison: 2,
    },
    sdkContractType: {
      sale: 1,
      rent: 2,
    },
  },
  detailBannerConfig: {
    id: 'div-gpt-ad-1647620072861-0',
    name: '/19273281/billboard_detail_desktop',
    width: '970',
    height: '250',
    display: 1,
    isActive: '1',
  },
  provincePathSuffix: 'zone',
  homepageHeadline: {
    default: {
      transKey: 'lu_header_default_payoff_generic', // i18n-extract-keys ["lu_header_default_payoff_generic"]
    },
  },
  homepageExandibleCategories: [
    CATEGORIES.RESIDENZIALE,
    CATEGORIES.NUOVE_COSTRUZIONI,
  ],
  oAuth: {
    google: {
      clientId:
        '352354294398-e14u7cq213a9g7ti8ot8l5h5l28bfd95.apps.googleusercontent.com',
    },
  },
  didomiNoticeID: isProd ? '3AGBngJn' : 'mMjGG6bd',
  timeZone: 'Europe/Luxembourg',
  agencyBanners: {
    banners: [
      {
        id: 'div-gpt-ad-1647620072861-0',
        name: '/19273281/billboard_detail_desktop',
        width: '970',
        height: '250',
        display: 1,
        isActive: '1',
      },
      {
        id: 'div-gpt-ad-1699287136550-0',
        name: '/19273281/IMU_afterpagination_agencyprofile_mobile',
        width: '300',
        height: '250',
        display: 1,
        isActive: '1',
      },
      {
        id: 'div-gpt-ad-1699286985714-0',
        name: '/19273281/leaderboard_afterpagination_agencyprofile_desktop',
        width: '728',
        height: '90',
        display: 1,
        isActive: '1',
      },
    ],
    settings: { nascondiSeVuoti: '1' },
  },
  agenciesWithBaseHeaderColor,
  segmentConfig: isProd
    ? {
        apiHost: 'catcher.immotop.lu/v1',
        apiKey: '5ORCdPus9HThu26dy5gt5wmtkdFe7O4h',
      }
    : {
        apiHost: 'catcher-staging.immotop.lu/v1',
        apiKey: 'D6FxgFlmM8eL0EB5rF2fiETVslUnKctE',
      },
  trackingReferrerRoutes: {
    publishdAds: '/advertise-property/',
    userSavedListings: '/user/saved/',
    userSavedSearch: '/user/searches/',
    evalueateBuilding: '/estimation/',
    userMessages: '/utente/messaggi/',
    userHiddenAds: '/utente/annunci/nascosti/',
    userLastAds: '/utente/annunci/recenti/',
    userLastSearch: '/utente/ricerche/recenti/',
    international: '/internazionale/',
    cityGuide: '/prix-immobilier/',
    mortgage: '/pret-immobilier/',
    searchList: '/search-list/',
    searchMap: '/search-map/',
    detail: '/annonces/',
    rent: '/location-',
    sell: '/vente-',
    sellBuild: '/vente-immeubles/',
    newConstruction: '/immobilier-neuf/',
    agency: '/agences-immobilieres/([a-z]+)',
    agencyDetail: '/agences-immobilieres/([0-9]+)',
    pro: '/pro/',
  },
};
